<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      top="2vh"
      title="Danh sách người nhận"
    >
      <div class="search-table">
        <el-select
            style="width: 150px"
            placeholder="Trạng thái"
            v-model="dataSearch.isApproved"
            @change="searchHeaderMethodPlus()"
            clearable
        >
          <el-option
              v-for="item in approvedListPlus"
              :key="item.label"
              :value="item.value"
              :label="item.label"
          ></el-option>
        </el-select>
        <el-select
            style="width: 150px"
            clearable
            placeholder="Trạng thái"
            v-model="dataSearch.userUnread"
            @change="searchHeaderMethodPlus()"
        >
          <el-option
              v-for="item in userUnreadList"
              :key="item.label"
              :value="item.value"
              :label="item.label"
          ></el-option>
        </el-select>
        <el-input
            style="width: 180px"
            placeholder="Nhập tên học sinh"
            clearable
            v-model="dataSearch.className"
            @change="searchHeaderMethodPlus()"
            @keyup.enter.native="searchHeaderMethodPlus()"
        >
        </el-input>
        <el-input
            style="width: 180px"
            type="number"
            placeholder="Nhập số điện thoại"
            clearable
            v-model="dataSearch.phone"
            @change="searchHeaderMethodPlus()"
            @keyup.enter.native="searchHeaderMethodPlus()"
        >
        </el-input>
      </div>
      <el-form
        label-width="140px"
        :model="receiverList"
        label-position="left"
        ref="receiverList"
      >
        <div class="table-content row-data">
          <el-table
            :empty-text="textTable"
            v-loading="loadingData"
            :element-loading-text="$tableLoading"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255,255,255, 0)"
            highlight-current-row
            :data="receiverList"
            :header-cell-style="tableHeaderColor"
            @selection-change="handleSelectionChange"
            border
            :max-height="500"
          >
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column
              type="index"
              label="STT"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="fullName"
              label="Tên người nhận"
              align="left"
            ></el-table-column>
            <el-table-column
              prop="className"
              label="Học sinh"
              align="left "
            ></el-table-column>
            <el-table-column
              width="190"
              prop="phone"
              label="Số điện thoại"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="appType"
              width="130"
              label="Đối tượng nhận"
              align="center"
            ></el-table-column>
            <!-- <el-table-column prop="schoolName" label="Lớp" align="center"></el-table-column> -->
            <el-table-column
              prop="userUnread"
              width="130"
              label="Trạng thái"
              align="center"
            ></el-table-column>
            <!-- <el-table-column prop="timeRead" label="Thời gian" align="center"></el-table-column> -->

            <el-table-column label="Tác vụ" align="center" width="252">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-if="!scope.row.isApproved"
                  type="success"
                  @click="handleApproved(scope.$index, scope.row)"
                  >Duyệt</el-button
                >

                <el-button
                  size="mini"
                  v-else
                  disabled
                  type="info"
                  @click="handleApproved(scope.$index, scope.row)"
                  >Duyệt</el-button
                >

                <el-button
                  size="mini"
                  type="success"
                  @click="handleRevoke(scope.$index, scope.row)"
                  v-if="scope.row.sendDel"
                  >Hiện lại</el-button
                >

                <el-button
                  v-else
                  size="mini"
                  type="warning"
                  @click="handleRevoke(scope.$index, scope.row)"
                  >Thu hồi</el-button
                >

                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)"
                  >Xóa</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
      <div class="tab-infor-button">
        <el-button type="danger" size="medium" @click="handleMultiDelete()">
          <i class="el-icon-delete"></i>
          <span>Xóa</span>
        </el-button>

        <el-button
          type="primary"
          size="medium"
          :loading="loadingButton"
          mini
          @click="handleMultiRevoke()"
        >
          <i class="el-icon-circle-check" />
          <span>Thu hồi</span>
        </el-button>

        <el-button
          type="success"
          size="medium"
          :loading="loadingButton"
          mini
          @click="handleMultiRevokeShow()"
        >
          <i class="el-icon-circle-check" />
          <span>Hiện lại</span>
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AppSendService from "@/services/AppSendService";

export default {
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      textTable: "",
      loadingData: true,
      idAppSend: "",
      indexColumn: "",
      loadingButton: false,
      receiverList: [],
      receiverListSearch: [],
      multipleSelection: [],
      dataSearch :{
        className: "",
        phone: "",
        userUnread:"",
        isApproved: true

      },
      approvedListPlus: [
        {label: "Đã duyệt", value: true},
        {label: "Chưa duyệt", value: false},
      ],
      userUnreadList: [
        {label: "Đã đọc", value: "Đã đọc"},
        {label: "Chưa đọc", value: "Chưa đọc"},
      ],
    };
  },
  methods: {
    searchHeaderMethodPlus(){
      let array = [];
      for (let i = 0; i < this.receiverListSearch.length; i++) {
        if (this.dataSearch.isApproved === ''){
          if (this.receiverListSearch[i].className.toLowerCase().split("-")[0].trim().includes(this.dataSearch.className.toLowerCase().split("-")[0].trim()) &&
              this.receiverListSearch[i].phone.toLowerCase().includes(this.dataSearch.phone.toLowerCase()) &&
              this.receiverListSearch[i].userUnread.toLowerCase().includes(this.dataSearch.userUnread.toLowerCase())){
            array.push(this.receiverListSearch[i])
          }
        }else if (this.receiverListSearch[i].className.toLowerCase().split("-")[0].trim().includes(this.dataSearch.className.toLowerCase().split("-")[0].trim()) &&
            this.receiverListSearch[i].phone.toLowerCase().includes(this.dataSearch.phone.toLowerCase()) &&
            this.receiverListSearch[i].userUnread.toLowerCase().includes(this.dataSearch.userUnread.toLowerCase()) &&
            this.receiverListSearch[i].isApproved === this.dataSearch.isApproved
        ){
          array.push(this.receiverListSearch[i])
        }
      }
      if (array.length === 0){
        this.textTable = this.$tableEmpty;
      }
      this.receiverList = array
    },
    tableHeaderColor() {
      return "background-color: #78a5e7;color: #fff;font-weight: bold;border:1px solid #fff";
    },
    closeDialog() {
      this.dataSearch.fullName = ""
      this.dataSearch.className = ""
      this.dataSearch.phone = ""
      this.dataSearch.userUnread = ""
      this.dataSearch.isApproved = true
      this.$refs["receiverList"].resetFields();
      this.$emit("dialog-close");
    },
    //click when click button
    closeDialogByButton(fromData) {
      this.$refs[fromData].resetFields();
      this.$emit("dialog-close");
      this.$refs.upload.clearFiles();
      this.showUpload = true;
    },
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
        .then((_) => {
          console.log(_);
          done();
        })
        .catch((_) => {
          console.log(_);
        });
    },
    getAllReceiversNotify(idAppSend) {
      this.receiverList = []
      this.receiverListSearch = []
      this.idAppSend = idAppSend;
      this.loadingData = true;
      this.textTable = "";
      AppSendService.getReceiversNotifyByIdUser(idAppSend)
        .then((response) => {
          this.receiverList = response.data.data;
          this.receiverListSearch = response.data.data;
          this.searchHeaderMethodPlus()
        })
        .catch((err) => {
          console.log(err);
        })
          .finally(() => {
        if (this.receiverList.length === 0) {
          this.textTable = this.$tableEmpty;
        }
        this.loadingData = false;
      });
    },
    handleDelete(index, row) {
      this.$confirm("Bạn có chắc chắn muốn xóa thông báo đã chọn?", "Thông báo!", {
        distinguishCancelAndClose: true,
        confirmButtonText: "Có",
        closeOnClickModal: false,
        cancelButtonText: "Không",
      }).then(() => {
        AppSendService.deleteReceiversNotifyById(row.id)
          .then((res) => {
            if (res.data.data) {
              this.$message({
                message: "Xóa thông báo thành công",
                type: "success",
              });
              this.getAllReceiversNotify(this.idAppSend);
            } else {
              this.$message({
                message: "Xóa thông báo thất bại",
                type: "error",
              });
            }
          })
          .catch(() => {
            this.$message({
              message: "Xóa thông báo thất bại",
              type: "error",
            });
          });
      });
    },

    handleMultiDelete() {
      let result = this.multipleSelection.map((a) => a.id);
      if (this.multipleSelection.length > 0) {
        this.$confirm("Bạn có chắc chắn muốn xóa thông báo đã chọn?", "Thông báo!", {
          distinguishCancelAndClose: true,
          confirmButtonText: "Có",
          closeOnClickModal: false,
          cancelButtonText: "Không",
        }).then(() => {
          AppSendService.deleteMultiReceiversNotifyByMutiId(result)
            .then((res) => {
              if (res.data.data) {
                this.$message({
                  message: "Xóa thông báo thành công",
                  type: "success",
                });
                this.getAllReceiversNotify(this.idAppSend);
              } else {
                this.$message({
                  message: "Xóa thông báo thất bại",
                  type: "error",
                });
              }
            })
            .catch(() => {
              this.$message({
                message: "Xóa thông báo thất bại",
                type: "error",
              });
            });
        });
      } else {
        this.$message({
          message: "Mời bạn chọn các thông báo muốn xóa",
          type: "warning",
        });
      }
    },

    handleRevoke(index, row) {
      AppSendService.updateRevokeReceiversNotifyById(row.id)
        .then((res) => {
          if (res.data.data) {
            this.$message({
              message: "Cập nhật trạng thái thành công",
              type: "success",
            });
            this.getAllReceiversNotify(this.idAppSend);
          } else {
            this.$message({
              message: "Cập nhật trạng thái thất bại",
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            message: "Cập nhật trạng thái thất bại",
            type: "error",
          });
        });
    },

    handleApproved(index, row) {
      AppSendService.updateApprovedReceiversNotifyById(row.id)
        .then((res) => {
          if (res.data.data) {
            this.$message({
              message: "Duyệt tin nhắn thành công",
              type: "success",
            });
            this.getAllReceiversNotify(this.idAppSend);
          } else {
            this.$message({
              message: "Duyệt tin nhắn thất bại",
              type: "error",
            });
          }
        })
        .catch(() => {
          this.$message({
            message: "Duyệt tin nhắn thất bại",
            type: "error",
          });
        });
    },

    handleMultiRevoke() {
      console.log("toang");
      let result = this.multipleSelection.map((a) => a.id);
      if (this.multipleSelection.length > 0) {
        AppSendService.updateMultiRevokeReceiversNotifyByMultiId(result)
          .then((res) => {
            if (res.data.data) {
              this.$message({
                message: "Cập nhật trạng thái thành công",
                type: "success",
              });
              this.getAllReceiversNotify(this.idAppSend);
            } else {
              this.$message({
                message: "Cập nhật trạng thái thành công",
                type: "error",
              });
            }
          })
          .catch(() => {
            this.$message({
              message: "Cập nhật trạng thái thành công",
              type: "error",
            });
          });
      } else {
        this.$message({
          message: "Mời bạn chọn thông báo muốn thu hồi",
          type: "warning",
        });
      }
    },
    handleMultiRevokeShow() {
      console.log("vào ok");
      let result = this.multipleSelection.map((a) => a.id);
      if (this.multipleSelection.length > 0) {
        AppSendService.updateMultiRevokeReceiversNotifyByMultiIdShow(result)
          .then((res) => {
            if (res.data.data) {
              this.$message({
                message: "Cập nhật trạng thái thành công",
                type: "success",
              });
              this.getAllReceiversNotify(this.idAppSend);
            } else {
              this.$message({
                message: "Cập nhật trạng thái thành công",
                type: "error",
              });
            }
          })
          .catch(() => {
            this.$message({
              message: "Cập nhật trạng thái thành công",
              type: "error",
            });
          });
      } else {
        this.$message({
          message: "Mời bạn chọn thông báo muốn thu hồi",
          type: "warning",
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style>
.hideUpload > div {
  visibility: hidden;
}
</style>
<style lang="scss" scoped>
// /deep/.el-input__inner,
// /deep/.el-textarea__inner {
//   border: none;
//   border-radius: 0;
//   resize: none;
//   padding-left: 0;
//   padding-right: 0;
//   border-bottom: 1px solid #d9d9d9;
// }

/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}

/deep/.upload-demo {
  text-align: center;
  .el-upload {
    width: 100%;
  }
}
/deep/.el-table th > .cell {
  text-align: center !important;
}

/deep/.el-avatar {
  display: inline;
  img {
    border-radius: 5px;
    width: 100%;
  }
}

/deep/.el-input.is-disabled .el-input__inner {
  text-align: center;
  color: #d13b93;
  font-weight: bold;
}
.el-select {
  display: block;
}

.tab-infor-button {
  text-align: right;
  margin-top: 20px;
}
.bg-purple {
  background: #d3dce6;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.group-radio-status {
  margin: 0 50px;
  .kids-status {
    margin-bottom: 10px;
  }
}
/deep/.el-tabs__nav {
  background: #a0a19ce0;
}
/deep/.el-tabs__item {
  color: white;
}
/deep/.el-tabs__item.is-active {
  color: white;
  background: #67c23a;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: "*";
  color: #f56c6c;
  margin-left: 3px;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: "";
  color: #f56c6c;
  margin-left: -4px;
}
.table-icon-1 {
  width: 30%;
  margin-left: 15%;
  display: inline-block;
}
.table-icon-2 {
  width: 30%;
  margin-right: 15%;
  float: right;
}

.button-left-class {
  margin-right: 5px;
  width: 150px;
}
.search-table {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
}

.search-table > * {
  margin-right: 5px; /* Khoảng cách 5px giữa các khối con */
}
</style>
