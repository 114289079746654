var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.showAdd
        ? _c(
            "div",
            { staticStyle: { "margin-top": "5px", "margin-bottom": "-20px" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "unlink-panels": "",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc",
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethodPlus()
                  },
                },
                model: {
                  value: _vm.dateStartEnd,
                  callback: function ($$v) {
                    _vm.dateStartEnd = $$v
                  },
                  expression: "dateStartEnd",
                },
              }),
              _c(
                "el-select",
                {
                  staticClass: "button-left-class",
                  staticStyle: { "margin-left": "5px" },
                  attrs: { placeholder: "Kiểu gửi" },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethodPlus()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.dateSick,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "dateSick", $$v)
                    },
                    expression: "dataSearch.dateSick",
                  },
                },
                _vm._l(_vm.dateSick, function (item) {
                  return _c("el-option", {
                    key: item.status,
                    attrs: { value: item.status, label: item.label },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "button-left-class",
                  staticStyle: { "margin-left": "5px" },
                  attrs: { clearable: "", placeholder: "Đối tượng nhận" },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethodPlus()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.receiverType,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "receiverType", $$v)
                    },
                    expression: "dataSearch.receiverType",
                  },
                },
                _vm._l(_vm.receiverType, function (item) {
                  return _c("el-option", {
                    key: item.status,
                    attrs: { value: item.status, label: item.label },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "button-left-class",
                  staticStyle: { "margin-left": "5px" },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethodPlus()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.approved,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "approved", $$v)
                    },
                    expression: "dataSearch.approved",
                  },
                },
                _vm._l(_vm.approvedListPlus, function (item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
              _c(
                "el-input",
                {
                  staticClass: "button-left-class",
                  staticStyle: { width: "255px" },
                  attrs: {
                    placeholder: "Nhập nội dung tìm kiếm",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethodPlus()
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchHeaderMethodPlus()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "title", $$v)
                    },
                    expression: "dataSearch.title",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.searchHeaderMethodPlus()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm.checkNameOrPhonePlus === false
                ? _c("el-button", { on: { click: _vm.additionFilter } }, [
                    _c("i", { staticClass: "el-icon-circle-plus" }),
                  ])
                : _c("el-button", { on: { click: _vm.additionFilter } }, [
                    _c("i", {
                      staticClass: "el-icon-remove-outline",
                      staticStyle: { color: "red" },
                    }),
                  ]),
              _vm.checkNameOrPhonePlus === true
                ? _c(
                    "el-input",
                    {
                      staticClass: "button-left-class",
                      staticStyle: { width: "300px", "margin-left": "5px" },
                      attrs: {
                        placeholder: "Nhập tên/số điện thoại",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.searchHeaderMethodPlus()
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.searchHeaderMethodPlus()
                        },
                      },
                      model: {
                        value: _vm.nameOrPhonePlus,
                        callback: function ($$v) {
                          _vm.nameOrPhonePlus = $$v
                        },
                        expression: "nameOrPhonePlus",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.searchHeaderMethodPlus()
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm.showHistory
        ? _c(
            "div",
            { staticStyle: { "margin-top": "5px", "margin-bottom": "-20px" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  "unlink-panels": "",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc",
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                },
                on: {
                  change: function ($event) {
                    return _vm.searchHeaderMethodTeacher()
                  },
                },
                model: {
                  value: _vm.dateStartEnd,
                  callback: function ($$v) {
                    _vm.dateStartEnd = $$v
                  },
                  expression: "dateStartEnd",
                },
              }),
              _c(
                "el-select",
                {
                  staticClass: "button-left-class",
                  staticStyle: { "margin-left": "5px" },
                  attrs: { placeholder: "Kiểu gửi" },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethodTeacher()
                    },
                  },
                  model: {
                    value: _vm.dataAddSearch.dateSick2,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataAddSearch, "dateSick2", $$v)
                    },
                    expression: "dataAddSearch.dateSick2",
                  },
                },
                _vm._l(_vm.dateSick2, function (item) {
                  return _c("el-option", {
                    key: item.status,
                    attrs: { value: item.status, label: item.label },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "button-left-class",
                  staticStyle: { "margin-left": "5px" },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethodTeacher()
                    },
                  },
                  model: {
                    value: _vm.dataAddSearch.approved,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataAddSearch, "approved", $$v)
                    },
                    expression: "dataAddSearch.approved",
                  },
                },
                _vm._l(_vm.approvedListTeacher, function (item) {
                  return _c("el-option", {
                    key: item.label,
                    attrs: { value: item.value, label: item.label },
                  })
                }),
                1
              ),
              _c(
                "el-input",
                {
                  staticClass: "button-left-class",
                  staticStyle: { width: "255px" },
                  attrs: {
                    placeholder: "Nhập nội dung tìm kiếm",
                    clearable: "",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.searchHeaderMethodTeacher()
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchHeaderMethodTeacher()
                    },
                  },
                  model: {
                    value: _vm.dataAddSearch.title,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataAddSearch, "title", $$v)
                    },
                    expression: "dataAddSearch.title",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.searchHeaderMethodTeacher()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _vm.checkNameOrPhoneTeacher === false
                ? _c(
                    "el-button",
                    { on: { click: _vm.additionFilterTeacher } },
                    [_c("i", { staticClass: "el-icon-circle-plus" })]
                  )
                : _c(
                    "el-button",
                    { on: { click: _vm.additionFilterTeacher } },
                    [
                      _c("i", {
                        staticClass: "el-icon-remove-outline",
                        staticStyle: { color: "red" },
                      }),
                    ]
                  ),
              _vm.checkNameOrPhoneTeacher === true
                ? _c(
                    "el-input",
                    {
                      staticClass: "button-left-class",
                      staticStyle: { width: "300px", "margin-left": "5px" },
                      attrs: {
                        placeholder: "Nhập tên/số điện thoại",
                        clearable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.searchHeaderMethodTeacher()
                        },
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.searchHeaderMethodTeacher()
                        },
                      },
                      model: {
                        value: _vm.nameOrPhoneTeacher,
                        callback: function ($$v) {
                          _vm.nameOrPhoneTeacher = $$v
                        },
                        expression: "nameOrPhoneTeacher",
                      },
                    },
                    [
                      _c("el-button", {
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: {
                          click: function ($event) {
                            return _vm.searchHeaderMethodTeacher()
                          },
                        },
                        slot: "append",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "lblthemlichsu" },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClickTab },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Nhà trường", name: "plus" } },
                [
                  _c(
                    "div",
                    { staticClass: "table-content row-data" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingData,
                              expression: "loadingData",
                            },
                          ],
                          ref: "multipleTable",
                          attrs: {
                            "empty-text": _vm.textTable,
                            "element-loading-text": _vm.$tableLoading,
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background":
                              "rgba(255,255,255, 0)",
                            data: _vm.feedbacklist,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                            border: "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              width: "50",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "createdBy",
                              "min-width": "150",
                              label: "Người gửi",
                              align: "left",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Thời gian gửi",
                              width: "140",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            scope.row.createdDate
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "sendType",
                              width: "100",
                              label: "Kiểu gửi",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "sendTitle",
                              label: "Tiêu đề",
                              "min-width": "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Nội dung",
                              width: "150",
                              align: "center ",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [_vm._v(_vm._s(scope.row.a))]),
                                    scope.row.sendContent != 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              color: "#67c23a",
                                              "text-decoration": "underline",
                                              "margin-left": "10px",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleViewDetail(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("(Xem)\n                ")]
                                        )
                                      : _c("el-button", {
                                          staticStyle: {
                                            color: "#67c23a",
                                            "text-decoration": "underline",
                                            "margin-left": "10px",
                                          },
                                          attrs: { disabled: "", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleViewDetail(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "numberFile",
                              label: "Số file",
                              width: "80",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Số người nhận",
                              width: "150",
                              align: "center ",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.coutUserSent)),
                                    ]),
                                    scope.row.coutUserSent !== 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              color: "#67c23a",
                                              "text-decoration": "underline",
                                              "margin-left": "10px",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleListReceiver(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("(Xem)\n                ")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm.checkPermission([
                            "notifyHistory_historyApp_update",
                          ])
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "Tác vụ",
                                  width: "260",
                                  fixed: "right",
                                  align: "center",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.approveRe
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "success",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleconfirm(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Duyệt\n                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: "",
                                                    type: "info",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Duyệt\n                "
                                                  ),
                                                ]
                                              ),
                                          scope.row.sendDel
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "success",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleRevokeUn(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Hủy thu hồi\n                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "warning",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleRevoke(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Thu hồi\n                "
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Xóa\n                ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2829997965
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "btt" },
                        [
                          _c(
                            "el-dropdown",
                            { on: { command: _vm.handleCommand } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button-over",
                                  attrs: { type: "success" },
                                },
                                [
                                  _vm._v(
                                    "\n                Tác vụ\n                "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-caret-bottom",
                                  }),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                { staticClass: "el-dropdown-menu-container" },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "handleRead" } },
                                    [
                                      _vm._v(
                                        "Duyệt thông báo\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "handleConfirm" } },
                                    [
                                      _vm._v(
                                        "Thu hồi thông báo\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    {
                                      attrs: { command: "handleDeleteManySms" },
                                    },
                                    [_vm._v("Xóa thông báo\n                ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "paging-click row-data",
                      staticStyle: { float: "right", "margin-bottom": "10px" },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": _vm.pageNumberPlus,
                          "page-sizes": _vm.$pageListDefaultNew,
                          "page-size": _vm.maxPageItem,
                          layout: _vm.$pageLayoutDefault,
                          total: _vm.pageTotalPlus,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChangePlus,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Giáo viên", name: "teacher" } },
                [
                  _c(
                    "div",
                    { staticClass: "table-content row-data" },
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingData,
                              expression: "loadingData",
                            },
                          ],
                          ref: "multipleTable",
                          attrs: {
                            "empty-text": _vm.textTable,
                            "element-loading-text": _vm.$tableLoading,
                            "element-loading-spinner": "el-icon-loading",
                            "element-loading-background":
                              "rgba(255,255,255, 0)",
                            data: _vm.smsappteacherlist,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                            border: "",
                          },
                          on: { "selection-change": _vm.handleSelectionChange },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "selection", width: "55" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              width: "50",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "createdBy",
                              label: "Người gửi",
                              "min-width": "150",
                              align: "left",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Thời gian gửi",
                              width: "140",
                              align: "center",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDateTime")(
                                            scope.row.createdDate
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "sendType",
                              label: "Kiểu gửi",
                              width: "100",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "sendTitle",
                              label: "Tiêu đề",
                              "min-width": "150",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Nội dung",
                              width: "150",
                              align: "center ",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [_vm._v(_vm._s(scope.row.a))]),
                                    scope.row.sendContent != 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              color: "#67c23a",
                                              "text-decoration": "underline",
                                              "margin-left": "10px",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleViewDetail(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("(Xem)\n                ")]
                                        )
                                      : _c("el-button", {
                                          staticStyle: {
                                            color: "#67c23a",
                                            "text-decoration": "underline",
                                            "margin-left": "10px",
                                          },
                                          attrs: { disabled: "", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleViewDetail(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "numberFile",
                              label: "Số file",
                              width: "80",
                              align: "center",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Số người nhận",
                              width: "150",
                              align: "center ",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row.coutUserSent)),
                                    ]),
                                    scope.row.coutUserSent != 0
                                      ? _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              color: "#67c23a",
                                              "text-decoration": "underline",
                                              "margin-left": "10px",
                                            },
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleListReceiver(
                                                  scope.$index,
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("(Xem)\n                ")]
                                        )
                                      : _c("el-button", {
                                          staticStyle: {
                                            color: "#67c23a",
                                            "text-decoration": "underline",
                                            "margin-left": "10px",
                                          },
                                          attrs: { disabled: "", type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleListReceiver(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm.checkPermission([
                            "notifyHistory_historyApp_update",
                          ])
                            ? _c("el-table-column", {
                                attrs: {
                                  label: "Tác vụ",
                                  width: "260",
                                  align: "center",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.approveRe
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "success",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleconfirmTC(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Duyệt\n                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: "",
                                                    type: "info",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Duyệt\n                "
                                                  ),
                                                ]
                                              ),
                                          scope.row.sendDel
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "success",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleRevokeUnTC(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Hủy thu hồi\n                "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "warning",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleRevokeTC(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "Thu hồi\n                "
                                                  ),
                                                ]
                                              ),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "danger",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete2(
                                                    scope.$index,
                                                    scope.row
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("Xóa\n                ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1016068104
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "btt" },
                        [
                          _c(
                            "el-dropdown",
                            { on: { command: _vm.handleCommand } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "button-over",
                                  attrs: { type: "success" },
                                },
                                [
                                  _vm._v(
                                    "\n                Tác vụ\n                "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-caret-bottom",
                                  }),
                                ]
                              ),
                              _c(
                                "el-dropdown-menu",
                                { staticClass: "el-dropdown-menu-container" },
                                [
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "confirmnotifi" } },
                                    [
                                      _vm._v(
                                        "Duyệt thông báo\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "revokenotifi" } },
                                    [
                                      _vm._v(
                                        "Thu hồi thông báo\n                "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "deletenotifi" } },
                                    [_vm._v("Xóa thông báo\n                ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "paging-click row-data",
                      staticStyle: { float: "right", "margin-bottom": "10px" },
                    },
                    [
                      _c("el-pagination", {
                        attrs: {
                          background: "",
                          "current-page": _vm.pageNumberTeacher,
                          "page-sizes": _vm.$pageListDefaultNew,
                          "page-size": _vm.maxPageItem,
                          layout: _vm.$pageLayoutDefault,
                          total: _vm.pageTotalTeacher,
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChangeTeacher,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("view-content", {
        ref: "ViewContent",
        attrs: { dialogVisible: _vm.showCreateDialog },
        on: {
          "dialog-close": function ($event) {
            _vm.showCreateDialog = false
          },
        },
      }),
      _c("ViewCoutUserSentSmsAppforSys", {
        ref: "ViewCoutUserSentSmsAppforSys",
        attrs: { dialogVisible: _vm.showCreateDialogViewCoutUserSent },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseShowMethod2()
          },
        },
      }),
      _c("ListReceiver", {
        ref: "ListReceiver",
        attrs: { dialogVisible: _vm.showListReceiver },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseListReceiverMethod()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }