var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Danh sách người nhận",
        visible: _vm.dialogVisible,
        width: "70%",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "hr" }, [_c("hr")]),
      _c(
        "div",
        { staticClass: "table-content row-data" },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                data: _vm.historyappsend,
                "highlight-current-row": "",
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "nameReiceiver",
                  label: "Tên người nhận",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phone",
                  label: "Số điện thoại",
                  align: "center ",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "Đối tượng", align: "center " },
              }),
              _c("el-table-column", {
                attrs: { prop: "className", label: "Lớp", align: "center " },
              }),
              _c("el-table-column", {
                attrs: { label: "Tác vụ", width: "260", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        !scope.row.approved
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleconfirm(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Duyệt")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "info" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleconfirm1(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Duyệt")]
                            ),
                        !scope.row.sendDel
                          ? _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "warning" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRevoke(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Thu hồi")]
                            )
                          : _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "success" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRevoke1(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Hủy thu hồi")]
                            ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.$index, scope.row)
                              },
                            },
                          },
                          [_vm._v("Xóa")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v("\n      " + _vm._s(_vm.historyappsend) + "\n    "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btt" },
            [
              _c(
                "el-button",
                {
                  staticClass: "button-over",
                  attrs: { type: "success" },
                  on: {
                    click: function ($event) {
                      return _vm.handleupdatemanyapproved()
                    },
                  },
                },
                [_vm._v("Duyệt\n      ")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "button-over",
                  attrs: { type: "warning" },
                  on: {
                    click: function ($event) {
                      return _vm.handleMultiDelelte()
                    },
                  },
                },
                [_vm._v("Thu hồi")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialogByButton("editGrade")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v("Đóng")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }